var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        maskClosable: false,
        confirmLoading: _vm.confirmLoading,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 0 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单号",
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "orderCode",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入订单号!",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['orderCode', {rules: [{ required: true, message: '请输入订单号!' }]}]",
                              },
                            ],
                            attrs: { placeholder: "请输入订单号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单类型",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["ctype", {}],
                                  expression: "['ctype',{}]",
                                },
                              ],
                              attrs: { placeholder: "请选择订单类型" },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("国内订单"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("国际订单"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单日期",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["orderDate", {}],
                                expression: "[ 'orderDate',{}]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              showTime: "",
                              format: "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 0 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单金额",
                          },
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["orderMoney", {}],
                                expression: "[ 'orderMoney', {}]",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入订单金额" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单备注",
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["content", {}],
                                expression: "['content', {}]",
                              },
                            ],
                            attrs: { placeholder: "请输入订单备注" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tabs",
            {
              on: { change: _vm.handleChangeTabs },
              model: {
                value: _vm.activeKey,
                callback: function ($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey",
              },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "客户信息", forceRender: true } },
                [
                  _c("j-editable-table", {
                    ref: "editableTable1",
                    attrs: {
                      loading: _vm.table1.loading,
                      columns: _vm.table1.columns,
                      dataSource: _vm.table1.dataSource,
                      maxHeight: 300,
                      rowNumber: true,
                      rowSelection: true,
                      actionButton: true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "机票信息", forceRender: true } },
                [
                  _c("j-editable-table", {
                    ref: "editableTable2",
                    attrs: {
                      loading: _vm.table2.loading,
                      columns: _vm.table2.columns,
                      dataSource: _vm.table2.dataSource,
                      maxHeight: 300,
                      rowNumber: true,
                      rowSelection: true,
                      actionButton: true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }